import React, { Component } from "react";
import { Row, Alert, Icon, message } from "antd";
import axios from "axios";
import "./index.scss";
import moment from "moment";
import { connect } from "react-redux";
import { SHOPIFY_NAME } from "../../../../constants";
import CounterCardWraper from "../Common/CounterCardWraper";
import CustomTable from "../Common/CustomTable";
import TransactionReportNav from "./TransactionReportNav";
import DailyCodAmountGraph from "../Common/Graph/Graph";
import TotalVsDeliveredDataGraph from "../Common/Graph/Graph";
import DeductedFromWallet from "./DeductedFromWallet";
import AddedInWallet from "./AddedInWallet";
import TotalProfitAndLoss from "./TotalProfitandLoss";

class TransactionsReport extends Component {
  state = {
    loading: false,
    ndrs: [],
    error: false,
    errorMsg: "",
    startDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),

    transactionsCounter: {},
    transactionsCounterLoading: false,
    transactionsCounterError: false,
    transactionsCounterErrorMsg: "",
    transactionsCounterErrorCode: "",

    dailyCodAmount: [],
    dailyCodAmountLoading: false,
    dailyCodAmountError: false,
    dailyCodAmountErrorMsg: "",
    dailyCodAmountErrorCode: "",

    totalVsDeliveredData: [],
    totalVsDeliveredLoading: false,
    sortingOrder: "",
    totalVsDeliveredDataError: false, //
    totalVsDeliveredDataErrorMsg: "",
    totalVsDeliveredDataErrorCode: "",

    deductedFromWalletData: {},
    deductedFromWalletLoading: false,
    deductedFromWalletError: false,
    deductedFromWalletErrorMsg: "",
    deductedFromWalletErrorCode: "",

    orderWiseSummaryData: [],
    orderWiseSummaryLoading: false,
    orderWiseSummaryError: false,
    orderWiseSummaryErrorMsg: "",
    orderWiseSummaryErrorCode: "",

    cumulativeOrderData: [],
    cumulativeOrderLoading: false,
    cumulativeOrderError: false,
    cumulativeOrderErrorMsg: "",
    cumulativeOrderErrorCode: "",

    pageSize: 10,
    page: 1,
    total: 0,

    pageSize2: 10,
    page2: 1,
    total2: 0,

    cumulativeDownloadCSVLoading: false,
    orderWiseSummaryDownloadCSVloading: false,
  };

  componentDidMount() {
    this.transactionsCounter();
    this.dailyCodAmount();
    this.totalVsDeliveredData();
    this.deductedFromWalletData();
    this.orderWiseSummary();
    this.cumulativeOrder();
  }

  componentWillUnmount() {
    if (this.cancelToken1) {
      this.cancelToken1.cancel();
    }
    if (this.cancelToken2) {
      this.cancelToken2.cancel();
    }
    if (this.cancelToken3) {
      this.cancelToken3.cancel();
    }
    if (this.cancelToken4) {
      this.cancelToken4.cancel();
    }
    if (this.cancelToken5) {
      this.cancelToken5.cancel();
    }
    if (this.cancelToken6) {
      this.cancelToken6.cancel();
    }
  }

  transactionsCounter() {
    this.setState(
      {
        transactionsCounterLoading: true,
      },
      () => {
        if (this.cancelToken1) {
          this.cancelToken1.cancel();
        }
        this.cancelToken1 = axios.CancelToken.source();
        axios({
          url:
            process.env.REACT_APP_API_URL + "/bqreporting/transaction_counters",
          method: "post",
          cancelToken: this.cancelToken1.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                transactionsCounter: res.data.data,
                transactionsCounterLoading: false,
                transactionsCounterError: false,
              });
            } else {
              this.setState({
                transactionsCounter: {},
                transactionsCounterError: true,
                transactionsCounterErrorMsg: res.data.msg,
                transactionsCounterLoading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   transactionsCounterLoading: true,
            // });

            if (!!e.request) {
              this.setState({
                transactionsCounterError: true,
                transactionsCounterErrorCode: e.request.status,
                transactionsCounterErrorMsg: e.request.statusText,
                transactionsCounterLoading: false,
              });
            } else {
              this.setState({
                transactionsCounterError: false,
                transactionsCounterErrorMsg: e.message,
                transactionsCounterLoading: true,
              });
            }
          });
      }
    );
  }

  dailyCodAmount() {
    this.setState(
      {
        dailyCodAmountLoading: true,
      },
      () => {
        if (this.cancelToken2) {
          this.cancelToken2.cancel();
        }
        this.cancelToken2 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/date_wise_cod_remittance",
          method: "post",
          cancelToken: this.cancelToken2.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                dailyCodAmount: res.data.data,
                dailyCodAmountLoading: false,
                dailyCodAmountError: false,
              });
            } else {
              this.setState({
                dailyCodAmount: [],
                dailyCodAmountError: true,
                dailyCodAmountErrorMsg: res.data.msg,
                dailyCodAmountLoading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                dailyCodAmountError: true,
                dailyCodAmountErrorCode: e.request.status,
                dailyCodAmountErrorMsg: e.request.statusText,
                dailyCodAmountLoading: false,
              });
            } else {
              this.setState({
                dailyCodAmountError: false,
                dailyCodAmountErrorMsg: e.message,
                dailyCodAmountLoading: true,
              });
            }
          });
      }
    );
  }

  totalVsDeliveredData() {
    this.setState(
      {
        totalVsDeliveredLoading: true,
      },
      () => {
        if (this.cancelToken3) {
          this.cancelToken3.cancel();
        }
        this.cancelToken3 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/transaction_comparision_graph",
          method: "post",
          cancelToken: this.cancelToken3.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            date_type: this.state.sortingOrder,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success == 1) {
              this.setState({
                totalVsDeliveredData: res.data.data,
                totalVsDeliveredLoading: false,
                totalVsDeliveredError: false,
                totalVsDeliveredErrorMsg: "",
              });
            } else {
              this.setState({
                totalVsDeliveredData: [],
                totalVsDeliveredDataError: true,
                totalVsDeliveredDataErrorMsg: res.data.msg,
                totalVsDeliveredLoading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   totalVsDeliveredLoading: true,
            // });

            if (!!e.request) {
              this.setState({
                totalVsDeliveredDataError: true,
                totalVsDeliveredDataErrorCode: e.request.status,
                totalVsDeliveredDataErrorMsg: e.request.statusText,
                totalVsDeliveredLoading: false,
              });
            } else {
              this.setState({
                totalVsDeliveredDataError: false,
                totalVsDeliveredDataErrorMsg: e.message,
                totalVsDeliveredLoading: true,
              });
            }
          });
      }
    );
  }

  deductedFromWalletData() {
    this.setState(
      {
        deductedFromWalletLoading: true,
      },
      () => {
        if (this.cancelToken4) {
          this.cancelToken4.cancel();
        }
        this.cancelToken4 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL + "/bqreporting/transaction_summary",
          method: "post",
          cancelToken: this.cancelToken4.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                deductedFromWalletData: res.data.data,
                deductedFromWalletLoading: false,
                deductedFromWalletError: false,
                deductedFromWalletErrorMsg: "",
              });
            } else {
              this.setState({
                deductedFromWalletData: {},
                deductedFromWalletError: true,
                deductedFromWalletErrorMsg: res.data.msg,
                deductedFromWalletLoading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   deductedFromWalletLoading: true,
            // });

            if (!!e.request) {
              this.setState({
                deductedFromWalletError: true,
                deductedFromWalletErrorCode: e.request.status,
                deductedFromWalletErrorMsg: e.request.statusText,
                deductedFromWalletLoading: false,
              });
            } else {
              this.setState({
                deductedFromWalletError: false,
                deductedFromWalletErrorMsg: e.message,
                deductedFromWalletLoading: true,
              });
            }
          });
      }
    );
  }

  orderWiseSummary() {
    this.setState(
      {
        orderWiseSummaryLoading: true,
      },
      () => {
        if (this.cancelToken5) {
          this.cancelToken5.cancel();
        }
        this.cancelToken5 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL + "/Bqreporting/order_wise_summary",
          method: "post",
          cancelToken: this.cancelToken5.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            pageSize: this.state.pageSize,
            page: this.state.page,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                total: res.data.total,
                orderWiseSummaryData: res.data.data,
                orderWiseSummaryLoading: false,
                orderWiseSummaryError: false,
                orderWiseSummaryErrorMsg: "",
              });
            } else {
              this.setState({
                orderWiseSummaryData: {},
                orderWiseSummaryError: true,
                orderWiseSummaryErrorMsg: res.data.msg,
                orderWiseSummaryLoading: false,
              });
            }
          })
          .catch((e) => {
            // this.setState({
            //   error: true,
            //   errorMsg: e.message,
            //   deductedFromWalletLoading: true,
            // });

            if (!!e.request) {
              this.setState({
                orderWiseSummaryError: true,
                orderWiseSummaryErrorCode: e.request.status,
                orderWiseSummaryErrorMsg: e.request.statusText,
                orderWiseSummaryLoading: false,
              });
            } else {
              this.setState({
                orderWiseSummaryError: false,
                orderWiseSummaryErrorMsg: e.message,
                orderWiseSummaryLoading: true,
              });
            }
          });
      }
    );
  }
  orderWiseSummaryDownloadCSV() {
    this.setState(
      {
        orderWiseSummaryDownloadCSVloading: true,
      },
      () => {
        axios({
          url:
            process.env.REACT_APP_API_URL + "/Bqreporting/order_wise_summary",
          method: "post",

          data: {
            downloadcsv: 1,
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ orderWiseSummaryDownloadCSVloading: false });

            if (res.data.success === 1) {
              message.success(res.data.msg);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({ orderWiseSummaryDownloadCSVloading: false });

            message.error(e.message);
          });
      }
    );
  }

  cumulativeOrder() {
    this.setState(
      {
        cumulativeOrderLoading: true,
      },
      () => {
        if (this.cancelToken6) {
          this.cancelToken6.cancel();
        }
        this.cancelToken6 = axios.CancelToken.source();

        axios({
          url: process.env.REACT_APP_API_URL + "/Bqreporting/order_revenue",
          method: "post",
          cancelToken: this.cancelToken6.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
            pageSize: this.state.pageSize2,
            page: this.state.page2,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.setState({
                total2: res.data.total,
                cumulativeOrderData: res.data.data,
                cumulativeOrderLoading: false,
                cumulativeOrderError: false,
                cumulativeOrderErrorMsg: "",
              });
            } else {
              this.setState({
                cumulativeOrderData: [],
                cumulativeOrderError: true,
                cumulativeOrderErrorMsg: res.data.msg,
                cumulativeOrderLoading: false,
              });
            }
          })
          .catch((e) => {
            if (!!e.request) {
              this.setState({
                cumulativeOrderError: true,
                cumulativeOrderErrorCode: e.request.status,
                cumulativeOrderErrorMsg: e.request.statusText,
                cumulativeOrderLoading: false,
              });
            } else {
              this.setState({
                cumulativeOrderError: false,
                cumulativeOrderErrorMsg: e.message,
                cumulativeOrderLoading: true,
              });
            }
          });
      }
    );
  }
  cumulativeDownloadCSV() {
    this.setState(
      {
        cumulativeDownloadCSVLoading: true,
      },
      () => {
        axios({
          url: process.env.REACT_APP_API_URL + "/Bqreporting/order_revenue",
          method: "post",

          data: {
            downloadcsv: 1,
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            this.setState({ cumulativeDownloadCSVLoading: false });

            if (res.data.success === 1) {
              message.success(res.data.msg);
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({ cumulativeDownloadCSVLoading: false });

            message.error(e.messages);
          });
      }
    );
  }

  onShowSizeChange(current, pageSize) {
    this.setState(
      {
        pageSize: pageSize,
      },
      () => this.orderWiseSummary()
    );
  }

  onShowSizeChange2(current, pageSize) {
    this.setState(
      {
        pageSize2: pageSize,
      },
      () => this.cumulativeOrder()
    );
  }

  goToPage(page) {
    this.setState(
      {
        page: page,
      },
      () => {
        this.orderWiseSummary();
      }
    );
  }

  goToPage2(page) {
    this.setState(
      {
        page2: page,
      },
      () => {
        this.cumulativeOrder();
      }
    );
  }

  changeFilter(e) {
    this.setState(
      {
        startDate: moment(e.startDate).format("YYYY-MM-DD"),
        endDate: moment(e.endDate).format("YYYY-MM-DD"),
      },
      () => {
        this.transactionsCounter();
        this.dailyCodAmount();
        this.totalVsDeliveredData();
        this.deductedFromWalletData();
        this.orderWiseSummary();
        this.cumulativeOrder();
      }
    );
  }

  startDateHandler(startDate) {
    if (!!startDate) {
      return moment(startDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  endDateHandler(endDate) {
    if (!!endDate) {
      return moment(endDate).format("DD MMM, YYYY");
    } else {
      return "";
    }
  }

  //

  dropdownChange(selected) {
    this.setState(
      {
        sortingOrder: selected,
        totalVsDeliveredLoading: true,
      },
      () => {
        if (this.cancelToken3) {
          this.cancelToken3.cancel();
        }
        this.cancelToken3 = axios.CancelToken.source();

        axios({
          url:
            process.env.REACT_APP_API_URL +
            "/bqreporting/transaction_comparision_graph",
          method: "post",
          cancelToken: this.cancelToken3.token,
          data: {
            store_id: this.props.match.params.storeid,
            start_date: this.state.startDate,
            date_type: selected,
            end_date: this.state.endDate,
          },
          withCredentials: true,
        })
          .then((res) => {
            if (res.data.success == 1) {
              this.setState({
                totalVsDeliveredData: res.data.data,
                totalVsDeliveredLoading: false,
                error: false,
                errorMsg: "",
              });
            } else {
              this.setState({
                totalVsDeliveredData: [],
                error: true,
                errorMsg: res.data.msg,
                totalVsDeliveredLoading: false,
              });
            }
          })
          .catch((e) => {
            this.setState({
              error: true,
              errorMsg: e.message,
              totalVsDeliveredLoading: true,
            });
          });
      }
    );
  }

  render() {
    const PageWrapper = (children) => {
      return (
        <>
          <div className="order-reporting-wraper">
            <TransactionReportNav
              filters={this.state}
              {...this.props}
              changeFilter={(e) => this.changeFilter(e)}
              changeHandler={(e) => this.changeHandler(e)}
              ndrs={this.state.ndrs}
              ndrcount={this.state.ndrcount}
              storeid={this.props.match.params.storeid}
              loading={false}
            />

            <Row className="tabs-row">{children}</Row>
          </div>
        </>
      );
    };

    return PageWrapper(
      <>
        {this.state.transactionsCounterLoading ? null : (
          <h2
            style={{ marginBottom: "12px" }}
            className="content-page-subtitle"
          >
            {" "}
            {`Data shown for your ${SHOPIFY_NAME} Orders between  `}
            {moment(this.state.startDate).format("DD MMM, YYYY")} -{" "}
            {moment(this.state.endDate).format("DD MMM, YYYY")}
          </h2>
        )}
        {this.state.transactionsCounterLoading ? null : (
          <div className="alert-wrap-reporting">
            <Alert
              style={{ marginBottom: "40px" }}
              message="Important Information"
              description="Please note the data below updates every 2 hours."
              type="info"
              showIcon
            />
            <span
              className="ex-ic"
              style={{
                width: "24px",
                height: "24px",
                fontSize: "24px",
                lineHeight: "24px",
                display: "inline-block",
                color: "#0068FF",
              }}
            >
              <Icon type="exclamation-circle" theme="filled" />
            </span>
          </div>
        )}

        <CounterCardWraper
          counterType="Transaction"
          error={this.state.transactionsCounterError}
          errorMsg={this.state.transactionsCounterErrorMsg}
          errorCode={this.state.transactionsCounterErrorCode}
          loading={this.state.transactionsCounterLoading}
          data={this.state.transactionsCounter}
        />
        {/* <DailyCodAmountGraph
          data={JSON.parse(JSON.stringify(this.state.dailyCodAmount))}
          originalData={this.state.dailyCodAmount}
          yAxisKey={"date"}
          error={this.state.dailyCodAmountError}
          errorCode={this.state.dailyCodAmountErrorCode}
          errorMsg={this.state.dailyCodAmountErrorMsg}
          firstBar={"amount"}
          graphName={"Daily COD Collection Amount"}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          loading={this.state.dailyCodAmountLoading}
          firstLegendText={"Daily COD Collection Amount (₹)"}
        /> */}
        <TotalVsDeliveredDataGraph
          error={this.state.totalVsDeliveredDataError}
          errorMsg={this.state.totalVsDeliveredErrorMsg}
          errorCode={this.state.totalVsDeliveredErrorCode}
          sortingOrder={this.state.sortingOrder}
          dropdownChange={(v) => this.dropdownChange(v)}
          data={JSON.parse(JSON.stringify(this.state.totalVsDeliveredData))}
          originalData={this.state.totalVsDeliveredData}
          yAxisKey={"date"}
          firstBar={"pp_cost"}
          secondBar={"delivered_pp_cost"}
          graphName={"Total Order Amount vs Delivered Order Amount"}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          loading={this.state.totalVsDeliveredLoading}
          firstLegendText={"Total Order Amount (₹)"}
          secondLegendText={"Delivered Order Amount (₹)"}
        />

        <CustomTable
          downloadCSV={() => this.cumulativeDownloadCSV()}
          data={this.state.cumulativeOrderData}
          downloadCSVLoading={this.state.cumulativeDownloadCSVLoading}
          loading={this.state.cumulativeOrderLoading}
          error={this.state.cumulativeOrderError}
          errorMsg={this.state.cumulativeOrderErrorMsg}
          errorCode={this.state.cumulativeOrderErrorCode}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          graphName={"Cumulative Order Wise Report "}
          currentPage={this.state.page2}
          pageSize={this.state.pageSize2}
          total={this.state.total2}
          goToPage={(e) => this.goToPage2(e)}
          onShowSizeChange={(a, b) => this.onShowSizeChange2(a, b)}
        />

        <CustomTable
          data={this.state.orderWiseSummaryData}
          downloadCSVLoading={this.state.orderWiseSummaryDownloadCSVloading}
          downloadCSV={() => this.orderWiseSummaryDownloadCSV()}
          loading={this.state.orderWiseSummaryLoading}
          error={this.state.orderWiseSummaryError}
          errorMsg={this.state.orderWiseSummaryErrorMsg}
          errorCode={this.state.orderWiseSummaryErrorCode}
          startDate={this.startDateHandler(this.state.startDate)}
          endDate={this.endDateHandler(this.state.endDate)}
          graphName={"Orders Wise Transactions Details"}
          currentPage={this.state.page}
          pageSize={this.state.pageSize}
          total={this.state.total}
          goToPage={(e) => this.goToPage(e)}
          onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)}
        />

        <div className="wallet-container">
          <DeductedFromWallet
            error={this.state.deductedFromWalletError}
            errorMsg={this.state.deductedFromWalletErrorMsg}
            errorCode={this.state.deductedFromWalletErrorCode}
            data={this.state.deductedFromWalletData}
            loading={this.state.deductedFromWalletLoading}
            startDate={this.startDateHandler(this.state.startDate)}
            endDate={this.endDateHandler(this.state.endDate)}
          />
          <div>
            <AddedInWallet
              error={this.state.transactionsCounterError}
              errorCode={this.state.transactionsCounterErrorCode}
              errorMsg={this.state.transactionsCounterErrorMsg}
              data={this.state.transactionsCounter}
              loading={this.state.transactionsCounterLoading}
              startDate={this.startDateHandler(this.state.startDate)}
              endDate={this.endDateHandler(this.state.endDate)}
            />
            <TotalProfitAndLoss
              error={
                this.state.transactionsCounterError ||
                this.state.deductedFromWalletError
              }
              errorCode={
                this.state.transactionsCounterErrorCode ||
                this.state.deductedFromWalletErrorCode
              }
              errorMsg={
                this.state.transactionsCounterErrorMsg ||
                this.state.deductedFromWalletErrorMsg
              }
              data={this.state.transactionsCounter}
              data2={this.state.deductedFromWalletData}
              loading={
                this.state.transactionsCounterLoading ||
                this.state.deductedFromWalletLoading
              }
              startDate={this.startDateHandler(this.state.startDate)}
              endDate={this.endDateHandler(this.state.endDate)}
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state.auth.auth.store,
});
export default connect(mapStateToProps)(TransactionsReport);
