import React, { Component } from "react";
import "./index.scss";
import axios from "axios";
import { Link } from "react-router-dom";

import { Button, DatePicker, message, Select, Spin, Divider, Icon } from "antd";
import Sorter from "../../Common/ShopifyDateSorter";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
class OrdersReportNav extends Component {
  state = {
    ndr: this.props.ndrs,
    allbtn: true,
    mount: false,
  };

  render() {
    let storeProductsData = this.props.filters.storeProductsData;

    return (
      <div className="order-transaction-nav fullorder_data">
        <div className=" Ndrnav ">
          <div className="ordfill">
            <div className="ordfillwrap">
              <div className="left" style={{ marginLeft: "7px" }}>
                <div
                  style={{
                    color: "#000",
                    fontSize: "12px",
                    letterSpacing: "0.032em",
                    lineHeight: "24px",
                  }}
                >
                  Reporting
                </div>
              </div>
              <div className="right">
                {/* <Sorter sortingOrder="" />{" "}
                <span style={{ color: "#898989" }}> | </span> */}
                <p
                  className="show_data_from"
                  style={{ marginLeft: "8px", color: "#6e6e6e" }}
                >
                  Show Data From:{" "}
                </p>
                <RangePicker
                  format="DD MMM, YYYY"
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                  style={{ marginLeft: "10px", maxWidth: "300px" }}
                  value={[
                    this.props.filters.startDate === "" ||
                    this.props.filters.startDate === null
                      ? ""
                      : moment(this.props.filters.startDate, "YYYY-MM-DD"),
                    this.props.filters.endDate === "" ||
                    this.props.filters.startDate === null
                      ? ""
                      : moment(this.props.filters.endDate, "YYYY-MM-DD"),
                  ]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Last 15 Days": [moment().subtract("days", 15), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    ],
                    "Last Year": [
                      moment()
                        .subtract(1, "year")
                        .startOf("year"),
                      moment()
                        .subtract(1, "year")
                        .endOf("year"),
                    ],
                  }}
                  onChange={(_, dateString) =>
                    this.props.changeFilter({
                      startDate: dateString[0],
                      endDate: dateString[1],
                    })
                  }
                />
              </div>
            </div>
            <div className="secondwrap">
              <div className="filtertab">
                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.allbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <button
                    className={this.state.allbtn ? "active" : ""}
                    value="rto"
                  >
                    Store Orders
                  </button>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.openndrbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <Link style={{ color: "#898989" }} to="./NdrReports">
                    <button
                      className={this.state.openndrbtn ? "active" : ""}
                      value=""
                    >
                      Shipments
                    </button>
                  </Link>
                </div>

                <div
                  className={
                    this.props.loading
                      ? "non-cursor-pointer tab hover-ndr-underline-animation "
                      : this.state.openndrbtn
                      ? "tab  hover-ndr-underline-animation-click"
                      : "tab hover-ndr-underline-animation"
                  }
                >
                  <Link style={{ color: "#898989" }} to="./transactionsReports">
                    <button
                      className={this.state.openndrbtn ? "active" : ""}
                      value=""
                    >
                      P&L
                    </button>
                  </Link>
                </div>
              </div>

              <div className="product-filter">
                {!this.props.filters.storeProductsLoading &&
                  !!this.props.filters.selectedStoreProducts &&
                  !!this.props.filters.selectedStoreProducts.length && (
                    <div
                      onClick={() =>
                        this.props.productsSetter(false, false, true)
                      }
                      className="custom-cross-ic"
                    >
                      x
                    </div>
                  )}

                <Select
                  placeholder="Select store product"
                  value={
                    !!this.props.filters.selectedStoreProducts
                      ? this.props.filters.selectedStoreProducts
                      : undefined
                  }
                  dropdownRender={(menu) => (
                    <div>
                      {menu}

                      {!this.props.filters.storeProductsLoading &&
                        !!this.props.filters.selectedStoreProducts &&
                        !!this.props.filters.selectedStoreProducts.length && (
                          <>
                            <Divider style={{ margin: "4px 0" }} />
                            <div
                              className="overlay-footer-bottom"
                              style={{ cursor: "pointer" }}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              <Button
                                loading={this.props.orderToVfStatusDataLoading}
                                disabled={this.props.orderToVfStatusDataLoading}
                                onClick={() =>
                                  this.props.productsSetter(false, true)
                                }
                              >
                                Apply
                              </Button>
                            </div>
                          </>
                        )}
                    </div>
                  )}
                  maxTagCount={2}
                  maxTagTextLength={8}
                  showArrow={false}
                  mode="multiple"
                  // suffixIcon={
                  //   <svg width="16" height="8" viewBox="0 0 16 8">
                  //     <g
                  //       id="Dropdown_Black_SuperParent"
                  //       transform="translate(16 8) rotate(180)"
                  //       fill="none"
                  //     >
                  //       <path d="M8,0l8,8H0Z" stroke="none"></path>
                  //       <path
                  //         d="M 7.999999523162842 1.414219856262207 L 2.414219856262207 7 L 13.58577919006348 7 L 7.999999523162842 1.414219856262207 M 7.999999523162842 0 L 16 8 L -9.5367431640625e-07 8 L 7.999999523162842 0 Z"
                  //         stroke="none"
                  //         fill="#898989"
                  //       ></path>
                  //     </g>
                  //   </svg>
                  // }
                  notFoundContent={
                    this.props.filters.storeProductsLoading ? (
                      <Icon type="loading" />
                    ) : (
                      <p>No Data</p>
                    )
                  }
                  loading={this.props.storeProductsLoading}
                  dropdownMatchSelectWidth
                  onChange={(e) => this.props.productsSetter(e, false)}
                >
                  {!!storeProductsData &&
                    !!storeProductsData.length &&
                    storeProductsData.map((item) => (
                      <Option value={item.product_shopifyid}>
                        {item.shopify_name}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_data" />
      </div>
    );
  }
}

export default OrdersReportNav;
